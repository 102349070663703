import auth0 from "auth0-js";
import EventEmitter from "events";
import authConfig from "../configs/auth0.config.json";
import { StorageService } from "./storage.service";
import ApiService from "./api.service";
import events from "../configs/eventsconfig.json";
const loginEvent = events.loginEvent;

const webAuth = new auth0.WebAuth({
  domain: authConfig.domain,
  clientID: authConfig.clientId,
  redirectUri: `${window.location.origin}/callback`,
  audience: "https://mujeeb.ai/api",
  responseType: "token id_token",
  scope: "openid profile email full_access"
});

class AuthService extends EventEmitter {
  idToken = null;

  profile = null;

  tokenExpiry = null;

  accessToken = null;

  // Starts the user login flow
  login(customState) {
    webAuth.authorize({
      appState: customState
    });
  }
  // Handles the callback request from Auth0
  handleAuthentication() {
    const locaWebAuth = webAuth;
    return new Promise((resolve, reject) => {
      locaWebAuth.parseHash((err, authResult) => {
        if (err) {
          reject(err);
        } else {
          resolve(authResult);
        }
      });
    })
      .then(authResult => {
        return this.loginHandler(authResult);
      })
      .catch(err => {
        throw err;
      });
  }

  loginHandler(authResult) {
    // upate the falg status.
    return (
      this.setLoginParameters(authResult)
        // emit an event.
        // event recievers are: Callback
        .then(authResult => {
          this.emit(events.renewToken, {
            loggedIn: true,
            profile: authResult.idTokenPayload,
            state: authResult.appState || {}
            // _redirectTo: authResult.appState._redirectTo
          });
        })
        .catch(error => {
          throw error;
        })
    );
  }

  // eslint-disable-next-line no-unused-vars
  renewTokens(congfig = {}) {
    // Check if the token is there (no logout has been occurred)
    return this.isAuthenticated()
      .then(isAuthenticated => {
        if (!isAuthenticated) {
          throw new Error("M-Not logged in");
        }
        return new Promise((resolve, reject) => {
          // TODO redirect the user to the prevous route.
          webAuth.checkSession({}, (err, authResult) => {
            if (err) {
              console.log(err);
              console.log("Auth0: error while checking session");
              reject(err);
            } else {
              resolve(authResult);
            }
          });
        });
      })
      .then(authResult => this.setLoginParameters(authResult))
      .then(authResult => {
        this.emit(events.renewToken, {
          loggedIn: true,
          authResult
        });
      })
      .catch(e => {
        console.log(e);
        this.emit(events.renewToken, {
          loggedIn: false
        });
      });
  }
  /**
   * Save the token, expiryDate and profile
   * @return {Promise} fullfiled with the authResult
   * @param {Object} authResult
   */
  setLoginParameters(authResult) {
    this.idToken = authResult.idToken;
    this.accessToken = authResult.accessToken;
    this.profile = authResult.idTokenPayload;
    // Convert the JWT expiry time from seconds to milliseconds
    this.tokenExpiry = new Date(this.profile.exp * 1000);
    return Promise.all([
      StorageService.saveFlag("true"),
      StorageService.saveExpiryDate(this.tokenExpiry),
      ApiService.setHeader(this.accessToken)
    ]).then(() => {
      return authResult;
    });
  }

  logOut() {
    this.idToken = null;
    this.tokenExpiry = null;
    this.profile = null;
    StorageService.removeFlag();
    StorageService.removePage();
    StorageService.removeExpiryDate();
    ApiService.removeHeader();
    StorageService.removeUser();

    webAuth.logout({
      returnTo: window.location.origin
    });

    this.emit(loginEvent, { loggedIn: false });
  }

  async isAuthenticated() {
    try {
      const expiryDate = await StorageService.getExpiryDate();
      const tokenNotExpired = expiryDate != null ? Date.now() < expiryDate : false;
      const value = await StorageService.getFlag();
      return tokenNotExpired && value === "true";
    } catch (error) {
      console.log(error);
      return false;
    }
  }
}

export default new AuthService();
