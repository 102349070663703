import * as R from "ramda";

export default {
  namespaced: true,
  state: {
    store: {
      sideNavOptions: [
        {
          to: "chatbot.settings",
          icon: "",
          text: "خيارات",
          withParams: true,
          param: "pageId",
          selected: true
        },
        { to: "chatbot.insights", icon: "", text: "إحصائيات", selected: false },
        { to: "chatbot.items", icon: "", text: "منتجات", selected: false },
        { to: "chatbot.categories", icon: "", text: "فئات", selected: false }
        // {
        //   to: "chatbot.delivery.initialization",
        //   icon: "",
        //   text: "التوصيل",
        //   selected: false
        // }
      ],
      titles: {
        itemsTable: "قائمة المنتجات",
        itemAdditon: "إضافة منتج",
        editingItem: "تعديل معلومات منتج"
      },
      navigationTabs: {
        categoryTabs: ["معلومات الفئة", "منتجات الفئة", "إضافة منتجات"]
      },
      buttons: {
        itemAddition: "إضافة منتج"
      },
      itemInputs: [
        {
          type: "text",
          title: "اسم المنتج",
          isRequired: true,
          name: "name",
          maxlength: 80,
          content: "هنا يمكنك إضافة اسم المنتج ويجب أن يكون فريداً"
        },
        {
          type: "textarea",
          title: "وصف المنتج",
          name: "description",
          content: "هنا يجب إضافة وصف للمنتج كاللون والحجم أو الاستطاعة أو الماركة والمواد المصنعة",
          maxlength: 640
        },
        {
          type: "text",
          title: "سعر المنتح",
          isInteger: true,
          isRequired: false,
          name: "price",
          content: "هنا يجب وضع اسم المنتج يمكنك هنا أن تضع السعر بالعملة التي ترغب بها."
        },
        {
          type: "switch",
          title: "عرض المنتج",
          name: "published",
          content: "يمكنك إخفاء المنتج من معرض منتجاتك من هنا",
          default: true
        },
        {
          type: "multi-select",
          title: "فئات المنتج",
          isRequired: true,
          name: "categories",
          optionsUrl: "categories",
          inputFor: "item",
          content: "الفئات التي يقع تحتها هذا المنتج (أحذية، ألبسة...)"
        },
        {
          type: "tags",
          title: "وسوم",
          isRequired: true,
          name: "tags",
          content: `هنا يمكنك إضافة بعض الوسوم لسهولة الوصول للمنتج ولتصنيف المنتجات عند البحث
            يجب عليط إضافة وسم واحد على الأقل
            وفصل الوسوم بالضغط على زر Enter
            `
        },
        {
          type: "image",
          title: "صورة المنتج",
          name: "image",
          isRequired: true,
          content: "هنا يمكنك وضع صورة للمنتج والقيام بتعديل شكلها لتتلاءم مع شكل بطاقة المنتج."
        },
        {
          type: "text",
          title: "الكمية",
          header: "تتبع كمية المنتج",
          name: "quantity",
          optional: true,
          isRequired: false,
          isInteger: true,
          content:
            "هذا الحقل اختياري يمكنك عبره إضافة كمية للمنتج ليتم تتبع الكمية في حال تفعيل ميزة الطلب عبر المجيب"
        },
        {
          type: "date",
          title: "اختر التاريخ",
          header: "إظهار المنتج لفترة زمنية محدودة",
          name: "visibility_duration",
          optional: true,
          isRequired: false,
          content:
            "يمكنك إظهار المنتج لفترة محددة، مثلا في فترة الاعياد أو المناسبات. يمكنك اختيار يوم واحد او عدة أيام."
        }
      ],
      itemsProps: [
        {
          title: "صورة المنتج",
          cssClass: "image",
          name: "image"
        },
        {
          title: "اسم المنتج",
          name: "name"
        },
        {
          title: "فئة المنتج",
          name: "category"
        },
        {
          title: "متغيرات",
          name: "variations"
        },
        {
          title: "متاح",
          cssClass: "switch-container",
          name: "published"
        },
        {
          title: "خيارات",
          cssClass: "control-container"
        }
      ],
      itemsAdditionProps: [
        {
          title: "صورة المنتج",
          cssClass: "image",
          name: "image"
        },
        {
          title: "اسم المنتج",
          name: "name"
        },
        // {
        //   title: 'خيارات',
        //   cssClass: 'control-container'
        // },
        {
          title: "تحديد",
          name: "checked",
          cssClass: "checkBox"
        }
      ],
      categoryInput: [
        {
          type: "text",
          title: "اسم الفئة",
          isRequired: true,
          name: "name",
          maxlength: 20,
          content: "يجب إضافة اسم للفئة التي تريد إضافتها"
        },
        {
          type: "select",
          title: "الفئة الأب",
          isRequired: true,
          name: "parent_category",
          optionsUrl: "categories",
          inputFor: "category",
          content: "اختر الفئة التي يجب أن تقع الفئة الحالية تحتها"
        },
        {
          type: "switch",
          title: "عرض الفئة",
          name: "published",
          content: "هنا يمكنك اختيار فيما إذا كانت هذه الفئة سيتم عرضها أم لا",
          default: true
        },
        {
          type: "date",
          title: "اختر التاريخ",
          header: "إظهار الفئة لفترة زمنية محدودة",
          name: "visibility_duration",
          optional: true,
          content: "يمكنك هنا اختيار فترة محددة لعرض هذه الفئة ضمنها"
        }
      ],
      categoriesProps: [
        {
          name: "name",
          title: "اسم الفئة",
          cssClass: { text: true }
        },
        // {
        //   name: 'itemsIncluded',
        //   title: 'عدد المنتجات',
        //   cssClass: { 'text': true }
        // },
        {
          name: "published",
          title: "إظهار الفئة",
          cssClass: "switch-container",
          default: true
        },
        {
          name: "control",
          title: "خيارات",
          cssClass: "control-container"
        }
      ],
      cartOption: true,
      categoryItemsProps: [
        {
          title: "صورة المنتج",
          cssClass: "image",
          name: "image"
        },
        {
          title: "اسم المنتج",
          name: "name"
        },
        {
          title: "خيارات",
          cssClass: "control-container"
        }
      ],
      settings: {
        groups: [
          {
            name: 3,
            title: "تخصيص الإظهار"
          },
          {
            name: 2,
            title: "خيارات العملة"
          }
        ],
        inputs: [
          {
            type: "select",
            title: "أبعاد صور المنتجات",
            isRequired: true,
            group: 3,
            name: "aspectRatio",
            inputFor: "aspectRatio",
            default: "square"
          },
          {
            type: "select",
            title: "اسم العملة المستخدم للإظهار",
            isRequired: true,
            group: 2,
            name: "currency",
            inputFor: "currency",
            default: "SYP"
          },
          {
            type: "text",
            title: "سعر التحويل",
            header: "ربط سعر المنتج بسعر عملة أخرى",
            group: 2,
            name: "conversion",
            optional: true,
            isRequired: false,
            isInteger: true
          }
        ]
      },
      welcomeCard: {
        buttons: ["المنتجات"]
      },
      itemCard: {
        title: "المنتج"
      }
    },
    services: {
      // sideNavOptions: ['خيارات', 'إحصائيات', 'منتجات', 'فئات'],
      sideNavOptions: [
        {
          to: "chatbot.settings",
          icon: "",
          text: "خيارات",
          withParams: true,
          param: "pageId",
          selected: true
        },
        { to: "chatbot.insights", icon: "", text: "إحصائيات", selected: false },
        { to: "chatbot.items", icon: "", text: "الخدمات", selected: false },
        { to: "chatbot.categories", icon: "", text: "الفئات", selected: false }
      ],
      titles: {
        itemsTable: "قائمة الخدمات",
        itemAdditon: "إضافة خدمة"
      },
      navigationTabs: {
        categoryTabs: ["معلومات الفئة", "الخدمات", "إضافة خدمات"]
      },
      buttons: {
        itemAddition: "إضافة خدمة"
      },
      itemInputs: [
        {
          type: "text",
          title: "اسم الخدمة",
          isRequired: true,
          name: "name",
          maxlength: 80,
          content: "هنا يمكنك إضافة اسم الخدمة"
        },
        {
          type: "textarea",
          title: "معلومات الخدمة",
          name: "description",
          content:
            "هنا يجب إضافة وصف للخدمة مثلا: دورة في اللغة الانكليزية مقدمة من شخص معين وسيعطى فيها سلسلة تدريسية معينة.",
          maxlength: 640
        },
        {
          type: "text",
          title: "سعر الخدمة",
          isInteger: true,
          isRequired: false,
          name: "price",
          content: "هنا يمكنك وضع سعر للخدمة المقدمة."
        },
        {
          type: "switch",
          title: "إتاحة الخدمة",
          name: "published",
          content: "يمكنك إخفاء الخدمة من معرض خدماتك من هنا",
          default: true
        },
        {
          type: "multi-select",
          title: "الفئة التي تندرج تحتها الخدمة",
          isRequired: true,
          name: "categories",
          optionsUrl: "categories",
          inputFor: "item",
          content: "الفئات التي تقع تحتها هذه الخدمة (لغة انكليزية، خدمات مصرفية...)"
        },
        {
          type: "tags",
          title: "وسوم",
          isRequired: true,
          name: "tags",
          content: `هنا يمكنك إضافة بعض الوسوم لسهولة الوصول للخدمة ولتصنيف الخدمات عند البحث
            يجب عليط إضافة وسم واحد على الأقل
            وفصل الوسوم بالضغط على زر Enter
            `
        },
        {
          type: "image",
          title: "صورة معبرة عن الخدمة",
          name: "image",
          isRequired: true,
          content:
            "هنا يمكنك وضع صورة معبرة عن الخدمة المقدمة والقيام بتعديل شكلها لتتلاءم مع شكل بطاقة الإظهار."
        },
        {
          type: "date",
          title: "اختر التاريخ",
          header: "إظهار الخدمة لفترة زمنية محدودة",
          name: "visibility_duration",
          optional: true,
          isRequired: false,
          content:
            "يمكنك إظهار الخدمة لفترة محددة، مثلا في فترة الاعياد أو المناسبات. يمكنك اختيار يوم واحد او عدة أيام."
        }
      ],
      itemsProps: [
        {
          title: "صورة الخدمة",
          cssClass: "image",
          name: "image"
        },
        {
          title: "اسم الخدمة",
          name: "name"
        },
        {
          title: "فئة الخدمة",
          name: "category"
        },
        {
          title: "متغيرات",
          name: "variations"
        },
        {
          title: "متاحة",
          cssClass: "switch-container",
          name: "published"
        },
        {
          title: "خيارات",
          cssClass: "control-container"
        }
      ],
      itemsAdditionProps: [
        {
          title: "صورة الخدمة",
          cssClass: "image",
          name: "image"
        },
        {
          title: "اسم الخدمة",
          name: "name"
        },
        // {
        //   title: 'خيارات',
        //   cssClass: 'control-container'
        // },
        {
          title: "تحديد",
          name: "checked",
          cssClass: "checkBox"
        }
      ],
      categoryInput: [
        {
          type: "text",
          title: "اسم الفئة",
          isRequired: true,
          name: "name",
          maxlength: 20,
          content: "يجب إضافة اسم للفئة التي تريد إضافتها"
        },
        {
          type: "select",
          title: "الفئة الأب",
          isRequired: true,
          name: "parent_category",
          optionsUrl: "categories",
          inputFor: "category",
          content: "اختر الفئة التي يجب أن تقع الفئة الحالية تحتها"
        },
        {
          type: "switch",
          title: "عرض الفئة",
          name: "published",
          content: "هنا يمكنك اختيار فيما إذا كانت هذه الفئة سيتم عرضها أم لا",
          default: true
        },
        {
          type: "date",
          title: "اختر التاريخ",
          header: "إظهار الفئة لفترة زمنية محدودة",
          name: "visibility_duration",
          optional: true,
          content: "يمكنك هنا اختيار فترة محددة لعرض هذه الفئة ضمنها"
        }
      ],
      categoriesProps: [
        {
          name: "name",
          title: "اسم الفئة",
          cssClass: { text: true }
        },
        // {
        //   name: 'itemsIncluded',
        //   title: 'عدد المنتجات',
        //   cssClass: { 'text': true }
        // },
        {
          name: "published",
          title: "إظهار الفئة",
          cssClass: "switch-container",
          default: true
        },
        {
          name: "control",
          title: "خيارات",
          cssClass: "control-container"
        }
      ],
      cartOption: true,
      categoryItemsProps: [
        {
          title: "صورة الخدمة",
          cssClass: "image",
          name: "image"
        },
        {
          title: "اسم الخدمة",
          name: "name"
        },
        {
          title: "خيارات",
          cssClass: "control-container"
        }
      ],
      settings: {
        groups: [
          {
            name: 2,
            title: "خيارات العملة"
          },
          {
            name: 3,
            title: "تخصيص الإظهار"
          }
        ],
        inputs: [
          {
            type: "select",
            title: "أبعاد بطاقة إظهار الخدمة",
            isRequired: true,
            group: 3,
            name: "aspectRatio",
            inputFor: "aspectRatio",
            default: "square"
          },
          {
            type: "select",
            title: "اسم العملة المستخدم للإظهار",
            isRequired: true,
            group: 2,
            name: "currency",
            inputFor: "currency",
            default: "SYP"
          }
        ]
      },
      welcomeCard: {
        buttons: ["الخدمات"]
      },
      itemCard: {
        title: "الخدمة"
      }
    },
    restaurant: {
      // sideNavOptions: ['خيارات', 'إحصائيات', 'منتجات', 'فئات'],
      sideNavOptions: [
        {
          to: "chatbot.settings",
          icon: "",
          text: "خيارات",
          withParams: true,
          param: "pageId",
          selected: true
        },
        { to: "chatbot.insights", icon: "", text: "إحصائيات", selected: false },
        { to: "chatbot.items", icon: "", text: "الأطباق", selected: false },
        { to: "chatbot.categories", icon: "", text: "الفئات", selected: false }
      ],
      titles: {
        itemsTable: "قائمة الأطباق",
        itemAdditon: "إضافة طبق"
      },
      navigationTabs: {
        categoryTabs: ["معلومات الفئة", "الأطباق", "إضافة أطباق"]
      },
      buttons: {
        itemAddition: "إضافة طبق"
      },
      itemInputs: [
        {
          type: "text",
          title: "اسم الطبق",
          isRequired: true,
          name: "name",
          maxlength: 80,
          content: "هنا يمكنك إضافة اسم الطبق"
        },
        {
          type: "textarea",
          title: "معلومات الطبق",
          name: "description",
          content: "هنا يجب إضافة وصف للطبق ومكوناته مثلا: فوتوتشيني بالجاج والفطر الطازج.",
          maxlength: 640
        },
        {
          type: "text",
          title: "سعر الطبق",
          isInteger: true,
          isRequired: false,
          name: "price",
          content: "هنا يمكنك وضع سعر للطبق."
        },
        {
          type: "switch",
          title: "إتاحة الطبق",
          name: "published",
          content: "يمكنك إخفاء الطبق من قائمة الطعام من هنا",
          default: true
        },
        {
          type: "multi-select",
          title: "الفئة التي يندرج تحتها الطبق",
          isRequired: true,
          name: "categories",
          optionsUrl: "categories",
          inputFor: "item",
          content: "الفئات التي يقع تحتها هذا الطبق (ساندويتش لحوم، همبرغر...)"
        },
        {
          type: "tags",
          title: "وسوم",
          isRequired: true,
          name: "tags",
          content: `هنا يمكنك إضافة بعض الوسوم لسهولة الوصول للطبق ولتصنيف الأطباق عند البحث
            يجب عليط إضافة وسم واحد على الأقل
            وفصل الوسوم بالضغط على زر Enter
            `
        },
        {
          type: "image",
          title: "صورة الطبق",
          name: "image",
          isRequired: false,
          content: "هنا يمكنك وضع صورة للطبق والقيام بتعديل شكلها لتتلاءم مع شكل بطاقة الإظهار."
        },
        {
          type: "date",
          title: "اختر التاريخ",
          header: "إظهار الطبق لفترة زمنية محدودة",
          name: "visibility_duration",
          optional: true,
          isRequired: false,
          content:
            "يمكنك إظهار الطبق لفترة محددة، مثلا في فترة الاعياد أو المناسبات. يمكنك اختيار يوم واحد او عدة أيام."
        }
      ],
      itemsProps: [
        {
          title: "صورة الطبق",
          cssClass: "image",
          name: "image"
        },
        {
          title: "اسم الطبق",
          name: "name"
        },
        {
          title: "فئة الطبق",
          name: "category"
        },
        {
          title: "متغيرات",
          name: "variations"
        },
        {
          title: "متاحة",
          cssClass: "switch-container",
          name: "published"
        },
        {
          title: "خيارات",
          cssClass: "control-container"
        }
      ],
      itemsAdditionProps: [
        {
          title: "صورة الطبق",
          cssClass: "image",
          name: "image"
        },
        {
          title: "اسم الطبق",
          name: "name"
        },
        // {
        //   title: 'خيارات',
        //   cssClass: 'control-container'
        // },
        {
          title: "تحديد",
          name: "checked",
          cssClass: "checkBox"
        }
      ],
      categoryInput: [
        {
          type: "text",
          title: "اسم الفئة",
          isRequired: true,
          name: "name",
          maxlength: 20,
          content: "يجب إضافة اسم للفئة التي تريد إضافتها"
        },
        {
          type: "select",
          title: "الفئة الأب",
          isRequired: true,
          name: "parent_category",
          optionsUrl: "categories",
          inputFor: "category",
          content: "اختر الفئة التي يجب أن تقع الفئة الحالية تحتها"
        },
        {
          type: "switch",
          title: "عرض الفئة",
          name: "published",
          content: "هنا يمكنك اختيار فيما إذا كانت هذه الفئة سيتم عرضها أم لا",
          default: true
        },
        {
          type: "date",
          title: "اختر التاريخ",
          header: "إظهار الفئة لفترة زمنية محدودة",
          name: "visibility_duration",
          optional: true,
          content: "يمكنك هنا اختيار فترة محددة لعرض هذه الفئة ضمنها"
        }
      ],
      categoriesProps: [
        {
          name: "name",
          title: "اسم الفئة",
          cssClass: { text: true }
        },
        // {
        //   name: 'itemsIncluded',
        //   title: 'عدد المنتجات',
        //   cssClass: { 'text': true }
        // },
        {
          name: "published",
          title: "إظهار الفئة",
          cssClass: "switch-container",
          default: true
        },
        {
          name: "control",
          title: "خيارات",
          cssClass: "control-container"
        }
      ],
      cartOption: true,
      categoryItemsProps: [
        {
          title: "صورة الطبق",
          cssClass: "image",
          name: "image"
        },
        {
          title: "اسم الطبق",
          name: "name"
        },
        {
          title: "خيارات",
          cssClass: "control-container"
        }
      ],
      settings: {
        groups: [
          {
            name: 1,
            title: "تخصيص الإظهار"
          },
          {
            name: 3,
            title: "خيارات العملة"
          }
        ],
        inputs: [
          // {
          //   type: "switch",
          //   title: "إيقاف المجيب",
          //   name: "published",
          //   group: 2,
          //   switchOptins: {
          //     trueValue: "يعمل",
          //     falseValue: "متوقف"
          //   }
          // },
          {
            type: "select",
            title: "أبعاد بطاقة إظهار الطبق",
            isRequired: true,
            group: 1,
            name: "aspectRatio",
            inputFor: "aspectRatio",
            default: "square"
          },
          {
            type: "select",
            title: "اسم العملة المستخدم للإظهار",
            isRequired: true,
            group: 3,
            name: "currency",
            inputFor: "currency",
            default: "SYP"
          }
        ]
      },
      welcomeCard: {
        buttons: ["المينو"]
      },
      itemCard: {
        title: "الطبق"
      }
    },
    realEstate: {
      sideNavOptions: ["خيارات", "إحصائيات", "عقارات", "فئات"],
      cartOption: false
    }
  },
  getters: {
    sideNavButtons: (state, getters, rootState, rootGetters) => {
      // return rootGetters["page/botRecipe"] != null && rootGetters["page/botRecipe"] != "customized"
      //   ? state[rootGetters["page/botRecipe"]].sideNavOptions
      //   : [];
      // if (rootGetters["page/botRecipe"] != null && rootGetters["page/botRecipe"] != "customized") {
      //   if (rootGetters["page/deliveringActivated"] != null) {
      //     return state[rootGetters["page/botRecipe"]].sideNavOptions.concat([{
      //       to: "chatbot.settings",
      //       icon: "",
      //       text: "خيارات",
      //       withParams: true,
      //       param: "pageId",
      //       selected: true
      //     }])
      //   } else {
      //     return state[rootGetters["page/botRecipe"]].sideNavOptions
      //   }
      // } else {
      //   return []
      // }
      return rootGetters["page/botRecipe"] != null && rootGetters["page/botRecipe"] != "customized"
        ? state[rootGetters["page/botRecipe"]].sideNavOptions
        : [];
    },
    itemInputs: (state, getters, rootState, rootGetters) => {
      return rootGetters["page/botRecipe"] != null && rootGetters["page/botRecipe"] != "customized"
        ? state[rootGetters["page/botRecipe"]].itemInputs
        : null;
    },
    categoryInput: (state, getters, rootState, rootGetters) => {
      return rootGetters["page/botRecipe"] != null && rootGetters["page/botRecipe"] != "customized"
        ? state[rootGetters["page/botRecipe"]].categoryInput
        : null;
    },
    itemsProps: (state, getters, rootState, rootGetters) => {
      return rootGetters["page/botRecipe"] != null && rootGetters["page/botRecipe"] != "customized"
        ? state[rootGetters["page/botRecipe"]].itemsProps
        : null;
    },
    itemsAdditionProps: (state, getters, rootState, rootGetters) => {
      return rootGetters["page/botRecipe"] != null && rootGetters["page/botRecipe"] != "customized"
        ? state[rootGetters["page/botRecipe"]].itemsAdditionProps
        : null;
    },
    categoriesProps: (state, getters, rootState, rootGetters) => {
      return rootGetters["page/botRecipe"] != null && rootGetters["page/botRecipe"] != "customized"
        ? state[rootGetters["page/botRecipe"]].categoriesProps
        : null;
    },
    botSettings: (state, getters, rootState, rootGetters) => {
      const settings =
        rootGetters["page/botRecipe"] != null && rootGetters["page/botRecipe"] != "customized"
          ? state[rootGetters["page/botRecipe"]].settings
          : null;
      return settings
        ? {
            groups: settings.groups,
            groupedInputs: R.groupBy(input => input.group)(settings.inputs),
            inputs: settings.inputs
          }
        : null;
    },
    categoryItemsProps: (state, getters, rootState, rootGetters) => {
      return rootGetters["page/botRecipe"] != null && rootGetters["page/botRecipe"] != "customized"
        ? state[rootGetters["page/botRecipe"]].categoryItemsProps
        : null;
    },
    welcomeCard: (state, getters, rootState, rootGetters) => {
      return rootGetters["page/botRecipe"] != null && rootGetters["page/botRecipe"] != "customized"
        ? state[rootGetters["page/botRecipe"]].welcomeCard
        : null;
    },
    welcomeCard_2: state => recipe => {
      return state[recipe].welcomeCard;
    },
    titles: (state, getters, rootState, rootGetters) => {
      return rootGetters["page/botRecipe"] != null && rootGetters["page/botRecipe"] != "customized"
        ? state[rootGetters["page/botRecipe"]].titles
        : null;
    },
    navigationTabs: (state, getters, rootState, rootGetters) => {
      return rootGetters["page/botRecipe"] != null && rootGetters["page/botRecipe"] != "customized"
        ? state[rootGetters["page/botRecipe"]].navigationTabs
        : null;
    },
    buttons: (state, getters, rootState, rootGetters) => {
      return rootGetters["page/botRecipe"] != null && rootGetters["page/botRecipe"] != "customized"
        ? state[rootGetters["page/botRecipe"]].buttons
        : null;
    },
    recipeItems: (state, getters, rootState, rootGetters) => {
      const recipe = rootGetters["page/botRecipe"] || null;
      switch (recipe) {
        case "store":
          return "منتجات";
        case "services":
          return "خدمات";
        default:
          return "عناصر";
      }
    },
    itemCardTitle: (state, getters, rootState, rootGetters) => {
      return rootGetters["page/botRecipe"] != null && rootGetters["page/botRecipe"] != "customized"
        ? state[rootGetters["page/botRecipe"]].itemCard.title
        : null;
    }
  }
};
