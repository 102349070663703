<template>
  <div class="m-loading" :style="positionStyle">
    <p v-if="text !== null && text !== ''" class="rtl mb-10 text-xl">{{ text }}</p>
    <fingerprint-spinner :animation-duration="1250" :size="size" color="#007AFE" />
  </div>
</template>

<script>
import FingerprintSpinner from "./fingerPrint";
export default {
  components: {
    FingerprintSpinner
  },
  props: {
    position: {
      type: String,
      default: ""
    },
    size: {
      type: Number,
      default: 100
    },
    cssStyle: {
      type: String,
      default: ""
    },
    text: {
      type: String
    }
  },
  computed: {
    positionStyle() {
      if (this.position) {
        return `position: ${this.position}; ${this.cssStyle}`;
      } else {
        return "";
      }
    }
  }
};
</script>

<style scoped lang="scss">
.m-loading {
  z-index: 702;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: visible;
  background-color: rgba(235, 235, 235, 0.411);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  p {
    color: #007afe;
  }
}
</style>
