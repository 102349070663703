import Vue from "vue";
import getAtPath from "../../utils/functions/getAtPath";
const primaryUserInfo = ["name", "picture", "sub"];
import localforage from "localforage";
import storageNames from "../../configs/storageKeysNames.json";

// [ "given_name", "family_name", "nickname", "name", "picture", "updated_at", "email", "email_verified", "iss", "sub", "aud", "iat", "exp", "at_hash", "nonce" ]
export default {
  namespaced: true,
  state: {
    name: null,
    picture: null,
    id: null,
    loginState: false
  },
  mutations: {
    setUser(state, user) {
      state.name = user.name;
      state.picture = user.picture;
      state.id = user.sub.split("|")[1];
    },
    initializeUser(state, user) {
      state.name = user.name;
      state.picture = user.picture;
      state.id = user.id;
    },
    removeUser(state) {
      state.name = null;
      state.id = null;
      state.picture = null;
    },
    setUserState(state, loginState) {
      state.loginState = loginState;
    }
  },
  actions: {
    setUser({ dispatch, commit, getters, rootGetters }, userInfo) {
      return new Promise((resolve, reject) => {
        if (!primaryUserInfo.every(property => getAtPath([property], userInfo) != null)) {
          reject("missing a primary userInfo property");
        }
        try {
          commit("setUser", userInfo);
        } catch (error) {
          reject("error in commiting users info to store.");
        }
        resolve();
      });
    },
    initializeUser({ commit }) {
      return Promise.all([
        localforage.getItem(storageNames.user),
        localforage.getItem(storageNames.TOKEN_KEY)
      ])
        .then(([user, loginState]) => {
          if (user) {
            commit("initializeUser", user);
          }
          commit("setUserState", loginState ? true : false);
        })
        .catch(err => {
          console.log(err);
          throw err;
        });
    }
  },
  getters: {
    user(state) {
      const user = { name: state.name, id: state.id, email: state.email, picture: state.picture };
      return user;
    },
    // eslint-disable-next-line no-unused-vars
    name(state, getters, rootState, rootGetters) {
      return state.name;
    },
    // eslint-disable-next-line no-unused-vars
    id(state, getters, rootState, rootGetters) {
      return state.id;
    },
    // eslint-disable-next-line no-unused-vars
    picture(state, getters, rootState, rootGetters) {
      return state.picture;
    },
    loginState(state, getters, rootState, rootGetters) {
      return state.loginState;
    }
  }
};
