<template>
  <div class="temp-spinner"></div>
</template>

<script>
import FingerprintSpinner from "./fingerPrint";
export default {
  components: {
    FingerprintSpinner
  },
  props: {
    position: {
      type: String,
      default: ""
    },
    size: {
      type: Number,
      default: 100
    },
    cssStyle: {
      type: String,
      default: ""
    },
    text: {
      type: String
    }
  },
  computed: {
    positionStyle() {
      if (this.position) {
        return `position: ${this.position}; ${this.cssStyle}`;
      } else {
        return "";
      }
    }
  }
};
</script>

<style scoped lang="scss">
.temp-spinner {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: red;
}
</style>
