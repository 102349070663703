import localforage from "localforage";
import storageNames from "../../configs/storageKeysNames.json";
export default store => {
  store.subscribe((mutation, state) => {
    if (mutation.type === "user/setUser") {
      if (state.user != null) {
        const { name, id, picture } = state.user;
        const user = { name, id, picture };
        if (Object.keys(user).every(userProperty => user[userProperty] != null)) {
          localforage.setItem(storageNames.user, { name, id, picture });
        }
      }
    }
  });
};
