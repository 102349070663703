import localforage from "localforage";
import storageKeyNames from "../configs/storageKeysNames.json";

localforage.config({
  name: "$1235SFd%009",
  storeName: "234kjadcM98"
});
/**
 * Manage the how Access Tokens are being stored and retreived from storage.
 *
 * Current implementation stores to localforage. Local Storage should always be
 *
 * accessed through this instace.
 * */
const StorageService = {
  async clearData() {
    return localforage
      .clear()
      .then(function() {
        // Run this code once the database has been entirely deleted.
        console.log("Database is now empty.");
      })
      .catch(function(err) {
        // This code runs if there were any errors
        console.log(err);
      });
  },
  // token flag
  async getFlag() {
    return localforage.getItem(storageKeyNames.TOKEN_KEY);
  },
  async saveFlag(accessToken) {
    return localforage.setItem(storageKeyNames.TOKEN_KEY, accessToken);
  },
  async removeFlag() {
    return localforage.removeItem(storageKeyNames.TOKEN_KEY);
  },
  // expiryDate
  async saveExpiryDate(expiryDate) {
    return localforage.setItem(storageKeyNames.expiryDate, expiryDate);
  },
  async getExpiryDate() {
    return localforage.getItem(storageKeyNames.expiryDate);
  },
  async removeExpiryDate() {
    return localforage.removeItem(storageKeyNames.expiryDate);
  },
  // page
  async removePage() {
    return localforage.removeItem(storageKeyNames.page);
  },
  async savePage(page) {
    return localforage.setItem(storageKeyNames.page, page);
  },
  async getPage() {
    return localforage.getItem(storageKeyNames.page);
  },
  async removeUser() {
    return localforage.removeItem(storageKeyNames.user);
  }
};

export { StorageService };
