import Vue from "vue";
export default {
  namespaced: true,
  state: {
    alreadyAddedItems: {},
    addedItems: {},
    removedItems: {}
  },
  mutations: {
    addToAddedItems(state, payload) {
      Vue.set(state.addedItems, payload.id, payload.item);
    },
    removeFromAddedItems(state, payload) {
      Vue.delete(state.addedItems, payload.id);
    },
    addToAlreadyAddedItems(state, payload) {
      Vue.set(state.alreadyAddedItems, payload.id, payload.item);
    },
    removeFromAlreadyAddedItems(state, payload) {
      Vue.delete(state.alreadyAddedItems, payload.id);
    },
    addToRemovedItems(state, payload) {
      Vue.set(state.removedItems, payload.id, payload.item);
    },
    removeFromRemovedItems(state, payload) {
      Vue.delete(state.removedItems, payload.id);
    },
    reset(state, payload) {
      state.addedItems = {};
      state.removedItems = {};
      state.alreadyAddedItems = {};
    }
  },
  actions: {
    addToAddedItems({ dispatch, commit, getters, rootGetters }, payload) {
      commit("addToAddedItems", payload);
    },
    removeFromAddedItems({ dispatch, commit, getters, rootGetters }, payload) {
      commit("removeFromAddedItems", payload);
    },
    addToAlreadyAddedItems({ dispatch, commit, getters, rootGetters }, payload) {
      commit("addToAlreadyAddedItems", payload);
    },
    removeFromAlreadyAddedItems({ dispatch, commit, getters, rootGetters }, payload) {
      commit("removeFromAlreadyAddedItems", payload);
    },
    addToRemovedItems({ dispatch, commit, getters, rootGetters }, payload) {
      commit("addToRemovedItems", payload);
    },
    removeFromRemovedItems({ dispatch, commit, getters, rootGetters }, payload) {
      commit("removeFromRemovedItems", payload);
    },
    reset({ dispatch, commit, getters, rootGetters }, payload) {
      commit("reset");
    }
  },
  getters: {
    alreadyAddedItems: (state, getters, rootState, rootGetters) => {
      return state.alreadyAddedItems;
    },
    addedItems: (state, getters, rootState, rootGetters) => {
      return state.addedItems;
    },
    removedItems: (state, getters, rootState, rootGetters) => {
      return state.removedItems;
    }
  }
};
