import Vue from "vue";
import Router from "vue-router";
import authService from "./services/auth0.service";
// import loadingSpinner from "./components/loading/loading-spinner.vue";
import loadingSpinner from "./components/loading/temp-spinner.vue";
import getAtPath from "./utils/functions/getAtPath";
import HOME from "./views/Home.vue";
import store from "./store/index";
import { EventBus } from "./utils/EventBus";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/callback",
      name: "callback",
      component: () => import("./components/Callback"),
      meta: { public: true }
    },
    {
      path: "/dashboard",
      name: "dashboard",
      component: () => import("./views/dashboard.vue"),
      children: [
        {
          path: "servicestopped",
          name: "serviceNotAvailable",
          component: () => ({
            // The component to load (should be a Promise)
            // eslint-disable-next-line prettier/prettier
            component: import("./components/serviceInvalidation"),
            // A component to use while the async component is loading
            loading: loadingSpinner,
            // Delay before showing the loading component. Default: 200ms.
            delay: 10,
            timeout: 3000
          }),
          meta: { public: true }
        },
        {
          path: "chatbot",
          name: "chatbot",
          component: () => import(/* webpackChunkName: "chatbot" */ "./views/chatbot"),
          meta: { serviceValidityChecke: true, service: "chatbot" },
          children: [
            {
              path: "insights",
              name: "chatbot.insights",
              component: () => ({
                // The component to load (should be a Promise)
                // eslint-disable-next-line prettier/prettier
                component: import(/* webpackChunkName: "chatbot-insihgts" */ "./components/chatbot/insights/insights"),
                // A component to use while the async component is loading
                loading: loadingSpinner,
                // Delay before showing the loading component. Default: 200ms.
                delay: 10,
                timeout: 3000
              })
            },
            {
              path: "delivery",
              name: "chatbot.delivery",
              component: () => ({
                // eslint-disable-next-line prettier/prettier
                component: import(/* webpackChunkName: "chatbot-delivery" */ "./components/chatbot/delivering/delivery"),
                loading: loadingSpinner,
                delay: 10,
                timeout: 3000
              }),
              children: [
                {
                  path: "",
                  name: "chatbot.delivery.pending",
                  component: () =>
                    import(
                      /* webpackChunkName: "chatbot-delivery" */ "./components/chatbot/delivering/requests/pendingRequests"
                    )
                },
                {
                  path: "done",
                  name: "chatbot.delivery.delivered",
                  component: () =>
                    import(
                      /* webpackChunkName: "chatbot-delivery" */ "./components/chatbot/delivering/requests/deliveredRequests"
                    )
                },
                {
                  path: "buyers",
                  name: "chatbot.delivery.buyers",
                  component: () =>
                    import(
                      /* webpackChunkName: "chatbot-delivery" */ "./components/chatbot/delivering/buyers"
                    ),
                  children: [
                    {
                      path: "table",
                      name: "chatbot.delivery.buyers.table",
                      component: () =>
                        import(
                          /* webpackChunkName: "chatbot-delivery" */ "./components/chatbot/delivering/buyers/buyersTable"
                        )
                    },
                    {
                      path: ":id",
                      props: true,
                      name: "chatbot.delivery.buyers.info",
                      component: () =>
                        import(
                          /* webpackChunkName: "chatbot-delivery" */ "./components/chatbot/delivering/buyers/buyerInfo"
                        )
                    }
                  ]
                }
              ]
            },
            {
              path: "init",
              name: "chatbot.delivery.initialization",
              // component: () => import("./components/chatbot/insights/insights") // TODO rename the component to chatbotInsights
              component: () => ({
                // The component to load (should be a Promise)
                // eslint-disable-next-line prettier/prettier
                component: import(/* webpackChunkName: "chatbot-insihgts" */ "./components/chatbot/delivering/delivery-initializer"),
                // A component to use while the async component is loading
                loading: loadingSpinner,
                // Delay before showing the loading component. Default: 200ms.
                delay: 1,
                timeout: 3000
              }),
              beforeEnter: (to, from, next) => {
                if (store.getters["page/deliveringIsActivated"]) {
                  next({ name: "chatbot.delivery.pending" });
                } else {
                  next();
                }
              }
            },
            {
              path: "initialization",
              name: "chatbot.initialization",
              // component: () => import("./components/chatbot/chatBot_initializer")
              component: () => ({
                // The component to load (should be a Promise)
                // eslint-disable-next-line prettier/prettier
                component: import(/* webpackChunkName: "chatbot-init" */ "./components/chatbot/chatBot_initializer"),
                // A component to use while the async component is loading
                loading: loadingSpinner,
                // Delay before showing the loading component. Default: 200ms.
                delay: 10,
                timeout: 3000
              })
            },
            {
              path: "items",
              name: "chatbot.items",
              // component: () => import("./components/chatbot/shop/itemsTable")
              component: () => ({
                // The component to load (should be a Promise)
                // eslint-disable-next-line prettier/prettier
                component: import(/* webpackChunkName: "chatbot-items" */ "./components/chatbot/shop/itemsTable"),
                // A component to use while the async component is loading
                loading: loadingSpinner,
                // Delay before showing the loading component. Default: 200ms.
                delay: 10,
                timeout: 3000
              })
            },
            {
              path: "items/item/:productId?",
              name: "chatbot.items.item",
              // component: () => import("./components/chatbot/shop/item")
              component: () => ({
                // The component to load (should be a Promise)
                // eslint-disable-next-line prettier/prettier
                component: import(/* webpackChunkName: "chatbot-item-data" */ "./components/chatbot/shop/item"),
                // A component to use while the async component is loading
                loading: loadingSpinner,
                // Delay before showing the loading component. Default: 200ms.
                delay: 10,
                timeout: 3000
              })
            },
            {
              path: "categories",
              name: "chatbot.categories",
              // component: () => import("./components/chatbot/shop/categoriesTable") // create chatbotCategories to host the view router : chatbotCategoriesTable
              component: () => ({
                // The component to load (should be a Promise)
                // eslint-disable-next-line prettier/prettier
                component: import(/* webpackChunkName: "chatbot-cats" */ "./components/chatbot/shop/categoriesTable"),
                // A component to use while the async component is loading
                loading: loadingSpinner,
                // Delay before showing the loading component. Default: 200ms.
                delay: 10,
                timeout: 3000
              }) // create chatbotCategories to host the view router : chatbotCategoriesTable
            },
            {
              path: "categories/category/:categoryId?",
              name: "chatbot.category",
              // component: () => import("./components/chatbot/shop/category")
              component: () => ({
                // The component to load (should be a Promise)
                // eslint-disable-next-line prettier/prettier
                component: import(/* webpackChunkName: "chatbot-cat-data" */ "./components/chatbot/shop/category"),
                // A component to use while the async component is loading
                loading: loadingSpinner,
                // Delay before showing the loading component. Default: 200ms.
                delay: 10,
                timeout: 3000
              })
            },
            {
              path: "settings/:pageId?",
              name: "chatbot.settings",
              // component: () => import("./components/chatbot/chatbotSettings") // rename chatbot_settings
              component: () => ({
                // The component to load (should be a Promise)
                // eslint-disable-next-line prettier/prettier
                component: import(/* webpackChunkName: "chatbot-settings" */ "./components/chatbot/chatbotSettings"),
                // A component to use while the async component is loading
                loading: loadingSpinner,
                // Delay before showing the loading component. Default: 200ms.
                delay: 10,
                timeout: 3000
              }),
              children: [
                {
                  path: "properties",
                  name: "chatbot.settings.properties",
                  component: () =>
                    import(
                      /* webpackChunkName: "chatbot-settings" */ "./components/chatbot/chatbotProperties"
                    )
                },
                {
                  path: "services",
                  name: "chatbot.settings.services",
                  component: () =>
                    import(
                      /* webpackChunkName: "chatbot-settings" */ "./components/chatbot/chatbotServices"
                    )
                }
              ]
            }
          ]
        },
        {
          path: "commenter",
          name: "Dashboard.Commenter",
          // component: () => import(/* webpackChunkName: "reply" */ "./views/Commenter"),
          // component: () => import(/* webpackChunkName: "reply" */ "./views/Commenter"),
          component: () => ({
            // The component to load (should be a Promise)
            // eslint-disable-next-line prettier/prettier
            component: import(/* webpackChunkName: "reply" */ "./views/Commenter"),
            // A component to use while the async component is loading
            loading: loadingSpinner,
            // Delay before showing the loading component. Default: 200ms.
            delay: 10,
            timeout: 3000
          }),
          meta: { serviceValidityChecke: true, service: "commenter" },
          children: [
            {
              path: "new",
              name: "Commenter.NewCommenter",
              // component: () =>
              //   lazyLoadView(
              //     import(/* webpackChunkName: "reply" */ "./components/commenter/AddCommenter")
              //   )
              component: () => ({
                // The component to load (should be a Promise)
                // eslint-disable-next-line prettier/prettier
                component: import(/* webpackChunkName: "reply" */ "./components/commenter/AddCommenter"),
                // A component to use while the async component is loading
                loading: loadingSpinner,
                // Delay before showing the loading component. Default: 200ms.
                delay: 10,
                timeout: 3000
              })
            },
            {
              path: "comments-template",
              name: "Commenter.CommentsTemplate",
              component: () => ({
                component: import(
                  /* webpackChunkName: "reply" */ "./components/commenter/commentsTemplate"
                ),
                loading: loadingSpinner,
                delay: 10,
                timeout: 3000
              })
            },
            {
              path: "comments-control",
              name: "Commenter.CommentsControl",
              component: () => ({
                component: import(/* webpackChunkName: "reply" */ "./components/commenter/control"),
                loading: loadingSpinner,
                delay: 10,
                timeout: 3000
              })
            },
            {
              path: "browse",
              name: "Commenter.BrowseComments",
              // component: () =>
              //   lazyLoadView(
              //     import(/* webpackChunkName: "reply" */ "./components/commenter/browseReplies")
              //   )
              component: () => ({
                // The component to load (should be a Promise)
                // eslint-disable-next-line prettier/prettier
                component: import(/* webpackChunkName: "reply_browse" */ "./components/commenter/browseReplies"),
                // A component to use while the async component is loading
                loading: loadingSpinner,
                // Delay before showing the loading component. Default: 200ms.
                delay: 1,
                timeout: 3000
              })
            }
          ]
        },
        {
          path: "documentation",
          name: "Dashboard.Documentation",
          // component: () =>
          //   import(/* webpackChunkName: "documentation" */ "./views/infoViews/Documentation"),
          component: () => ({
            // The component to load (should be a Promise)
            // eslint-disable-next-line prettier/prettier
            component: import(/* webpackChunkName: "documentation" */ "./views/infoViews/Documentation"),
            // A component to use while the async component is loading
            loading: loadingSpinner,
            // Delay before showing the loading component. Default: 200ms.
            delay: 10,
            timeout: 3000
          }),
          meta: { public: true }
        },
        {
          path: "control",
          name: "Dashboard.Control",
          component: () => ({
            // eslint-disable-next-line prettier/prettier
            component: import(/* webpackChunkName: "control" */ "./views/infoViews/Control"),
            loading: loadingSpinner,
            delay: 10,
            timeout: 3000
          }),
          meta: { public: true }
        },
        {
          path: "notifications",
          name: "Dashboard.Notifications",
          // component: () =>
          //   import(/* webpackChunkName: "notifications" */ "./views/infoViews/Notifications")
          component: () => ({
            // The component to load (should be a Promise)
            // eslint-disable-next-line prettier/prettier
            component:  import(/* webpackChunkName: "notifications" */ "./views/infoViews/Notifications"),
            // A component to use while the async component is loading
            loading: loadingSpinner,
            // Delay before showing the loading component. Default: 200ms.
            delay: 10,
            timeout: 3000
          })
        },
        {
          path: "manage",
          name: "Dashboard.Manage",
          component: () => import(/* webpackChunkName: "manage" */ "./views/infoViews/Manage"),
          // component: () => ({
          //   // The component to load (should be a Promise)
          //   // eslint-disable-next-line prettier/prettier
          //   component: () => import(/* webpackChunkName: "manage" */ "./views/infoViews/Manage"),
          //   // A component to use while the async component is loading
          //   loading: loadingSpinner,
          //   // Delay before showing the loading component. Default: 200ms.
          //   delay: 10,
          //   timeout: 3000
          // }),
          meta: { public: true },
          children: [
            {
              path: "addpage",
              name: "ManageBots.AddPage",
              // component: () =>
              //   import(/* webpackChunkName: "manage" */ "./components/manageBots/add_new_page")
              component: () => ({
                // The component to load (should be a Promise)
                // eslint-disable-next-line prettier/prettier
                component: import(/* webpackChunkName: "manage-addpage" */ "./components/manageBots/add_new_page"),
                // A component to use while the async component is loading
                loading: loadingSpinner,
                // Delay before showing the loading component. Default: 200ms.
                delay: 10,
                timeout: 3000
              })
            },
            {
              path: "browsepages",
              name: "ManageBots.BrowsePages",
              // component: () =>
              //   import(/* webpackChunkName: "manage" */ "./components/manageBots/browse_pages")
              component: () => ({
                // The component to load (should be a Promise)
                // eslint-disable-next-line prettier/prettier
                component: import(/* webpackChunkName: "manage-browsepages" */ "./components/manageBots/browse_pages"),
                // A component to use while the async component is loading
                loading: loadingSpinner,
                // Delay before showing the loading component. Default: 200ms.
                delay: 10,
                timeout: 3000
              })
            }
          ]
        },
        {
          path: "callUs",
          name: "Dashboard.callUs",
          // meta: { serviceValidityChecke: true, service: "callUs" },
          component: () => import(/* webpackChunkName: "callUs" */ "./views/contact-us")
        }
      ]
    },
    {
      path: "/",
      name: "home",
      component: HOME,
      meta: { public: true }
    },
    {
      path: "selectpage",
      name: "pageSelection",
      component: () => ({
        // The component to load (should be a Promise)
        // eslint-disable-next-line prettier/prettier
        component: import(/* webpackChunkName: "selectpage" */ "./views/pageSelection.vue").then(m => m.default),
        // A component to use while the async component is loading
        loading: loadingSpinner,
        // Delay before showing the loading component. Default: 200ms.
        delay: 10,
        timeout: 3000
      }),
      meta: { public: true }
    },
    {
      path: "fetching",
      name: "loadingScreen",
      component: () => import("./views/loadingScreen"),
      meta: { public: true }
    },
    {
      path: "*",
      name: "notFound",
      component: () => import("./views/notfound.vue"),
      meta: { public: true }
    }
  ]
});

router.beforeEach(async (to, from, next) => {
  const serviceValidityChecke = to.matched.some(record => record.meta.serviceValidityChecke);
  const isPublic = to.matched.some(record => record.meta.public);
  const routeRecord = to.matched.find(record => record.meta.serviceValidityChecke);
  if (isPublic) {
    return next();
  } else {
    const loggedIn = await authService.isAuthenticated();
    if (!loggedIn) {
      authService.login({ _redirectTo: to.path });
    } else {
      const pageVlidity = store.getters["page/validity"];
      if (!pageVlidity) {
        return next({ name: "serviceNotAvailable" });
      }
      if (serviceValidityChecke) {
        const serviceState = store.getters["page/serviceIsValid"](routeRecord.meta.service);
        if (typeof serviceState === "boolean" && !serviceState) {
          return next({ name: "serviceNotAvailable" });
        }
        if (to.name === "Dashboard.Commenter") {
          next({ name: "Commenter.NewCommenter" });
        }
        if (to.name === "chatbot.delivery.initialization") {
          if (store.getters["page/deliveringIsActivated"]) {
            next({ name: "chatbot.delivery.pending" });
          } else {
            next();
          }
        }
        return next();
      }
    }
  }
  next();
});

router.onError(function(err) {
  console.log(err);
});

// Lazy-loads view components, but with better UX. A loading view
// will be used if the component takes a while to load, falling
// back to a timeout view in case the page fails to load. You can
// use this component to lazy-load a route with:
//
// component: () => lazyLoadView(import('@views/my-view'))
//
// NOTE: Components loaded with this strategy DO NOT have access
// to in-component guards, such as beforeRouteEnter,
// beforeRouteUpdate, and beforeRouteLeave. You must either use
// route-level guards instead or lazy-load the component directly:
//
// component: () => import('@views/my-view')
//
function lazyLoadView(AsyncView) {
  const AsyncHandler = () => ({
    component: AsyncView,
    // A component to use while the component is loading.
    // loading: require('@views/_loading.vue').default,
    loading: loadingSpinner,
    // Delay before showing the loading component.
    // Default: 200 (milliseconds).
    delay: 200
    // A fallback component in case the timeout is exceeded
    // when loading the component.
    // error: require('@views/_timeout.vue').default,
    // Time before giving up trying to load the component.
    // Default: Infinity (milliseconds).
    // timeout: 10000,
  });

  return Promise.resolve({
    functional: true,
    render(h, { data, children }) {
      // Transparently pass any props or children
      // to the view component.
      return h(AsyncHandler, data, children);
    }
  });
}

export default router;
