export default {
  /**
   * Add reyframes to body as style block
   * @param name string
   * @param frames string
   */
  appendKeyframes: function(name, frames) {
    const sheet = document.createElement("style");
    if (!sheet) {
      return;
    }
    sheet.setAttribute("id", name);
    sheet.innerHTML = `@keyframes ${name} {${frames}}`;
    document.body.appendChild(sheet);
  },
  /**
   * Remove reyframes from body
   * @param name string
   */
  removeKeyframes: function(name) {
    const sheet = document.getElementById(name);
    if (!sheet) {
      return;
    }
    const sheetParent = sheet.parentNode;
    sheetParent.removeChild(sheet);
  }
};
