/* eslint-disable linebreak-style */
import Vue from "vue";
import Vuex from "vuex";
import router from "../router";
import localforage from "localforage";
import storageNames from "../configs/storageKeysNames.json";
import authentication from "../services/auth0.service";
import getAtPath from "../utils/functions/getAtPath";
// MODULES
import user from "./modules/user.module";
import page from "./modules/page.module";
import windowWidth from "./modules/window.width.module";
import recipesConfig from "./modules/recipies.configs.module";
import itemsTable from "./modules/items.module";
import notifications from "./modules/notifications.module";
import itemsBelongings from "./modules/itemsBelongings.js";
import delivery from "./modules/delifery.module.js";
// PLUGINS
import userPlugin from "../plugins/vuex/user.vuex.plugin";
import routerPugin from "../plugins/vuex/route.vuex.plugin";
import userLoggingState from "../plugins/vuex/userloggingState.plugin";
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    notifications,
    user,
    page,
    windowWidth,
    recipesConfig,
    itemsTable,
    itemsBelongings,
    delivery
  },
  state: {
    authenticatingUser: false,
    loggingState: false,
    pages: []
  },
  getters: {
    authenticatingUser: state => state.authenticatingUser,
    loggingState: state => state.loggingState,
    pages: state => state.pages
  },
  mutations: {
    setAuthenticatingUser(state, authenticatingUser) {
      return new Promise((resolve, reject) => {
        try {
          state.authenticatingUser = authenticatingUser;
          resolve();
        } catch (error) {
          reject(error);
        }
      });
    },
    setLoggingState(state, loggingState) {
      state.loggingState = loggingState;
    },
    setPages(state, pages) {
      state.pages = pages;
    }
  },
  actions: {
    setAuthenticatingUser({ commit }, setAuthenticatingUser) {
      commit("setAuthenticatingUser", setAuthenticatingUser);
    },
    setLoggingState({ commit }, currentState) {
      commit("setLoggingState", currentState);
    },
    initializeLoggingState({ commit, state }) {
      return localforage
        .getItem(storageNames.loggingState)
        .then(loggingState => {
          commit("setLoggingState", !!loggingState);
        })
        .then(() => state.loggingState);
    },
    InitializeRouter(_, appState) {
      return localforage
        .getItem(storageNames.route)
        .then(async value => {
          // console.log("InitializeRouter...", value);
          const isAuthenticated = await authentication.isAuthenticated();
          if (value != null) {
            if (isAuthenticated && (value.name == "home" || value.name == "loadingScreen")) {
              router.push("/dashboard");
            } else if (value.name !== "callback") {
              router.push(value.fullPath);
            }
          } else {
            router.push(getAtPath(["_redirectTo"], appState) || "/dashboard");
          }
        })
        .catch(err => {
          console.log(err);
          router.push("/dashbaord");
        });
    },
    setPages({ commit }, pages) {
      console.log("setting pages ", pages);
      commit("setPages", pages);
    }
  },
  plugins: [userPlugin, routerPugin, userLoggingState]
});
