import Vue from "vue";
import ApiService from "../../services/api.service";
import Quicksort from "optimized-quicksort";
function compareFunction(a, b) {
  if (a.date == null || b.date == null) {
    return -1;
  }
  if (a.date > b.date) {
    return -1;
  }
  if (a.date < b.date) {
    return 1;
  }
  // a must be equal to b
  return 0;
}
export default {
  namespaced: true,
  state: {
    notifications: [],
    homeMsg: "",
    summaryType: "dark",
    modal: null
  },
  mutations: {
    setNotifications(state, notifications) {
      state.notifications = notifications;
    },
    removeAllNotification(state) {
      state.notifications = [];
    },
    hideOneNotification(state, id) {
      const notification = state.notifications.find(notification => notification.id === id);
      Vue.set(notification, "show", false);
      Vue.set(notification, "dismissed", true);
    },
    setSummaryType(state, summaryType) {
      state.summaryType = summaryType;
    },
    setHomeMsg(state, msg) {
      state.homeMsg = msg;
    }
  },
  actions: {
    initializeNotifications({ state, commit, rootState, rootGetters }) {
      return new Promise((resolve, reject) => {
        let notifications =
          typeof rootGetters["page/notifications"] !== "undefined" &&
          Object.keys(rootGetters["page/notifications"]).length > 0
            ? Object.values(rootGetters["page/notifications"])
            : [];
        notifications.map(notification => {
          Vue.set(notification, "show", true);
        });

        notifications = Quicksort.sort(notifications, compareFunction);

        commit("setNotifications", notifications);

        const notificationTypes = notifications.reduce((res, notification) => {
          res[notification.type] = true;
          return res;
        }, {});

        if (Object.keys(notificationTypes).indexOf("danger") !== -1) {
          commit("setSummaryType", "danger");
        } else if (Object.keys(notificationTypes).indexOf("warn") !== -1) {
          commit("setSummaryType", "warn");
        } else if (Object.keys(notificationTypes).indexOf("success") !== -1) {
          commit("setSummaryType", "success");
        } else {
          commit("setSummaryType", "dark");
        }
        resolve(true);
      });
    },
    archiveAllNotifications({ state, commit, rootState, rootGetters }) {
      const options = {
        method: "delete",
        url: `/services/notifications/users/${rootGetters["user/id"]}/pages/${rootGetters["page/id"]}`,
        responseType: "json"
      };
      return ApiService.customRequest(options).then(({ data }) => {
        if (data.success) {
          commit("removeAllNotification");
        }
        return data.success;
      });
    },
    setHomeMsg({ state, commit, rootState }, msg) {
      commit("setHomeMsg", msg);
    },
    hideAllNotifications({ state, commit, rootState, rootGetters }) {
      state.notifications.map(notification => {
        commit("hideOneNotification", notification.id);
      });
      const options = {
        method: "put",
        url: `/users/${rootGetters["user/id"]}/pages/${rootGetters["page/id"]}/notifications`,
        responseType: "json"
      };
      return this.$api.customRequest(options);
    },
    hideOneNotification({ state, commit, rootState, rootGetters }, id) {
      commit("hideOneNotification", id);
      const options = {
        method: "put",
        url: `/services/notifications/users/${rootGetters["user/id"]}/pages/${rootGetters["page/id"]}/dismiss/${id}`,
        responseType: "json"
      };
      return ApiService.customRequest(options);
    }
  },
  getters: {
    notifications(state, getters, rootState) {
      return state.notifications || [];
    },
    homeMsg(state, getters, rootState) {
      return state.homeMsg;
    },
    summaryType(state, getters, rootState) {
      return state.summaryType;
    }
  }
};
