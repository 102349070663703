<template>
  <div class="m-loading" :style="positionStyle">
    <p
      v-if="text !== null && text !== ''"
      class="rtl mb-10 text-xl font-bold"
      :class="{ green: state, red: !state }"
    >
      {{ text }}
    </p>
    <div
      class="icon-container flex justify-center items-center content-center"
      :class="{ green: !!state, red: !state }"
    >
      <font-awesome-icon v-if="state" icon="check" class="green" size="2x" />
      <font-awesome-icon v-else icon="times" class="red" size="2x" />
    </div>
  </div>
</template>

<script>
import { EventBus } from "../utils/EventBus";
export default {
  name: "state-modal",
  props: {
    state: Boolean,
    position: {
      type: String,
      default: ""
    },
    size: {
      type: Number,
      default: 100
    },
    cssStyle: {
      type: String,
      default: ""
    },
    text: {
      type: String
    }
  },
  computed: {
    positionStyle() {
      if (this.position) {
        return `position: ${this.position}; ${this.cssStyle}`;
      } else {
        return "";
      }
    }
  },
  created() {
    EventBus.$emit("MODAL_OPENED");
  },
  destroyed() {
    EventBus.$emit("MODAL_CLOSED");
  }
};
</script>

<style scoped lang="scss">
.m-loading {
  z-index: 702;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: visible;
  background-color: rgba(255, 255, 255, 0.808);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  p {
    color: #007afe;
    &.red {
      color: $danger;
    }
    &.green {
      color: $success;
    }
  }
  .icon-container {
    width: 100px;
    height: 100px;
    border: 2px solid;
    border-radius: 50px;
    &.green {
      border-color: $success;
      svg {
        color: $success;
      }
    }
    &.red {
      border-color: $danger;
      svg {
        color: $danger;
      }
    }
  }
}
</style>
