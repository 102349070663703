import { StorageService } from "../../services/storage.service";
import getAtPath from "../../utils/functions/getAtPath";
import Vue from "vue";
import ApiService from "../../services/api.service";
// [ "given_name", "family_name", "nickname", "name", "picture", "updated_at", "email", "email_verified", "iss", "sub", "aud", "iat", "exp", "at_hash", "nonce" ]
export default {
  namespaced: true,
  state: {
    page: null,
    modal: null
  },
  mutations: {
    setPage(state, page) {
      state.page = page;
    },
    setchatbotSettings(state, payload) {
      if (payload != null) {
        Vue.set(state.page.services, "chatbot", payload);
      }
    },
    initializeService(state, serviceName) {
      Vue.set(state.page.services, serviceName, { initiated: true });
    },
    // Just like initializeService, but with the ability to set service data.
    setService(state, payload) {
      Vue.set(state.page.services, payload.serviceName, payload.serviceData);
    },
    changeServiceState(state, payload) {
      try {
        Vue.set(state.page.services[payload.serviceName], "enabled", payload.serviceState);
      } catch (error) {
        throw error;
      }
    },
    setChatBotRecipe(state, payload) {
      Vue.set(state.page.services, "chatbot", { recipe: payload.recipe, valid: payload.valid });
    },
    setModal(state, modal) {
      state.modal = modal;
    },
    setTours(state, payload) {
      if (payload != null) {
        Vue.set(state.page, "tours", payload);
      } else {
        Vue.set(state.page, "tours", {});
      }
    },
    stopShowingTour(state, payload) {
      if (state.page.tours) {
        Vue.set(state.page.tours, payload, { show: false });
      } else {
        state.page.tours = {};
        Vue.set(state.page.tours, payload, { show: false });
      }
    },
    updateService(state, payload) {
      state.page.services[payload.servicename] = Object.assign(
        state.page.services[payload.servicename],
        payload.change
      );
    },
    initDelivery(state, payload) {
      Vue.set(state.page.services.chatbot, "delivering", payload);
    }
  },
  actions: {
    readPageFromBrowserStorage({ commit }) {
      return StorageService.getPage()
        .then(page => {
          if (page) {
            commit("setPage", page);
          }
        })
        .catch(err => {
          console.log(err);
          throw new Error(err);
        });
    },
    changeChatbotSettings({ dispatch, commit, getters, rootGetters }, payload) {
      commit("setchatbotSettings", payload);
    },
    setPage({ dispatch, commit, getters, rootGetters }, page) {
      return new Promise((resolve, reject) => {
        commit("setPage", page);
        if (page.notifications) {
          dispatch("notifications/initializeNotifications", null, { root: true });
        }
        dispatch("initializeModal", null);
        resolve();
      });
    },
    initializeModal({ state, commit, rootState, rootGetters }) {
      const modal =
        typeof state.page.modal !== "undefined" && Object.keys(state.page.modal).length > 0
          ? state.page.modal
          : null;
      commit("setModal", modal);
    },
    initializeService({ dispatch, commit, getters, rootGetters }, service) {
      return new Promise((resolve, reject) => {
        try {
          commit("initializeService", service);
        } catch (error) {
          reject(error);
        }
        resolve();
      });
    },
    // Just like initializeService, but with the ability to set service data.
    setService({ dispatch, commit, getters, rootGetters }, payload) {
      return new Promise((resolve, reject) => {
        try {
          commit("setService", payload);
        } catch (error) {
          reject(error);
        }
        resolve();
      });
    },
    changeServiceState({ dispatch, commit, getters, rootGetters }, payload) {
      return new Promise((resolve, reject) => {
        if (getters["services"]) {
          try {
            commit("changeServiceState", payload);
            resolve();
          } catch (error) {
            reject(error);
          }
        } else {
          reject(new Error("لم يتم إيجاد عنصر الخدمات لهذه الصفحة"));
        }
      });
    },
    setChatBotRecipe({ dispatch, commit, getters, rootGetters }, payload) {
      return new Promise((resolve, reject) => {
        try {
          commit("setChatBotRecipe", payload);
          resolve(payload.recipe);
        } catch (error) {
          reject(error);
        }
      });
    },
    stopShowingTour({ dispatch, commit, getters, rootGetters }, tourName) {
      return ApiService.customRequest({
        url: `/services/tours/users/${rootGetters["user/id"]}/pages/${rootGetters["page/id"]}/chatbot/${tourName}`,
        method: "put",
        data: {
          state: false
        },
        responseType: "json"
      })
        .then(() => {
          commit("stopShowingTour", tourName);
        })
        .catch(error => {
          console.log(error);
        });
    },
    setTours({ dispatch, commit, getters, rootGetters }, payload) {
      commit("setTours", payload);
    },
    updateService({ dispatch, commit, getters, rootGetters }, payload) {
      commit("updateService", payload);
    },
    initDelivery({ dispatch, commit, getters, rootGetters }, payload) {
      return new Promise((resolve, reject) => {
        if (getters["chatbotSettings"]) {
          commit("initDelivery", payload);
          resolve();
        } else {
          reject("bot must be initiated first");
        }
      });
    }
  },
  getters: {
    page: (state, getters, rootState, rootGetters) => state.page,
    id: (state, getters, rootState, rootGetters) => getAtPath(["page", "id"], state),
    name: (state, getters, rootState, rootGetters) => getAtPath(["page", "name"], state),
    picture: (state, getters, rootState, rootGetters) => getAtPath(["page", "picture"], state),
    botRecipe: (state, getters, rootState, rootGetters) =>
      getAtPath(["page", "services", "chatbot", "recipe"], state),
    services: (state, getters, rootState, rootGetters) => getAtPath(["page", "services"], state),
    tours: state => tourName => getAtPath(["page", "tours", tourName, "show"], state),
    chatbotSettings: state => getAtPath(["page", "services", "chatbot"], state),
    notifications: (state, getters, rootState, rootGetters) =>
      getAtPath(["page", "notifications"], state),
    serviceIsInitialized: state => service =>
      !!getAtPath(["page", "services", service, "initiated"], state),
    serviceIsEnabled: state => service =>
      !!getAtPath(["page", "services", service, "enabled"], state),
    serviceIsValid: state => service => getAtPath(["page", "services", service, "valid"], state),
    deliveringIsActivated: state =>
      getAtPath(["page", "services", "chatbot", "delivering", "enabled"], state),
    validity: state => getAtPath(["page", "valid"], state),
    modal: state => getAtPath(["modal"], state)
  }
};
