export default (arr, obj) => {
  let val = obj;
  if (!val) {
    return null;
  }
  for (let i = 0; i < arr.length; i++) {
    const prop = arr[i];
    if (val[prop] == null) {
      return;
    }
    val = val[prop];
  }
  return val;
};
