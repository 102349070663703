import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/index";
import * as Sentry from "@sentry/browser";
import { Vue as VueIntegration } from "@sentry/integrations";

// eslint-disable-next-line handle-callback-err
Vue.config.errorHandler = function(err, vm, info) {
  // handle error
  // `info` is a Vue-specific error info, e.g. which lifecycle hook
  // the error was found in. Only available in 2.2.0+
  throw err;
};

Sentry.init({
  dsn: "https://aca9fd974f73406da800993f2cc9f89e@o391578.ingest.sentry.io/5259895",
  integrations: [new VueIntegration({ Vue, attachProps: true, logErrors: true })]
});

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faStore,
  faHandshake,
  faUtensils,
  faEdit,
  faTrashAlt,
  faPlus,
  faSort,
  faArrowsAltV,
  faChevronRight,
  faChevronLeft,
  faStopCircle,
  faMapMarkedAlt,
  faGlobeAfrica,
  faCity,
  faRoad,
  faPhone,
  faMap,
  faSortDown,
  faImage,
  faCut,
  faCloudUploadAlt,
  faTag,
  faCheck,
  faExternalLinkAlt,
  faTimes,
  faPen,
  faLink,
  faAt,
  faUndo,
  faExclamationTriangle,
  faBan,
  faCommentAlt,
  faBullhorn,
  faCompass,
  faBars,
  faTh,
  faCog,
  faExclamationCircle,
  faSignOutAlt,
  faFlag,
  faLifeRing,
  faBolt,
  faClock,
  faEye,
  faBook,
  faSyncAlt,
  faChartLine,
  faTags,
  faEllipsisV,
  faAlignRight,
  faSearch,
  faAngleRight,
  faTruck,
  faTruckLoading,
  faClipboardCheck,
  faChartBar,
  faSlidersH,
  faTasks,
  faListAlt,
  faEllipsisH,
  faAngleLeft,
  faUsers,
  faHistory,
  faSortUp,
  faArrowUp,
  faComments,
  faTimesCircle,
  faUser,
  faFileImage,
  faFile,
  faFilePdf
} from "@fortawesome/free-solid-svg-icons";

import { faYoutube, faFacebook } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(
  faFilePdf,
  faFile,
  faFileImage,
  faStore,
  faHandshake,
  faUtensils,
  faEdit,
  faTrashAlt,
  faPlus,
  faSort,
  faEdit,
  faArrowsAltV,
  faChevronRight,
  faChevronLeft,
  faStopCircle,
  faMapMarkedAlt,
  faGlobeAfrica,
  faCity,
  faRoad,
  faPhone,
  faMap,
  faSortDown,
  faImage,
  faCut,
  faCloudUploadAlt,
  faYoutube,
  faTag,
  faCheck,
  faExternalLinkAlt,
  faTimes,
  faPen,
  faLink,
  faAt,
  faUndo,
  faExclamationTriangle,
  faBan,
  faCommentAlt,
  faBullhorn,
  faCompass,
  faBars,
  faTh,
  faCog,
  faExclamationCircle,
  faSignOutAlt,
  faFlag,
  faLifeRing,
  faBolt,
  faClock,
  faEye,
  faBook,
  faFacebook,
  faSyncAlt,
  faChartLine,
  faTags,
  faEllipsisV,
  faAlignRight,
  faSearch,
  faAngleRight,
  faTruck,
  faTruckLoading,
  faClipboardCheck,
  faChartBar,
  faSlidersH,
  faTasks,
  faListAlt,
  faEllipsisH,
  faAngleLeft,
  faUsers,
  faHistory,
  faSortUp,
  faArrowUp,
  faComments,
  faTimesCircle,
  faUser
);
Vue.component("font-awesome-icon", FontAwesomeIcon);

import "@/assets/css/tailwind.css";
import "@/assets/css/shared.scss";
import "@/assets/css/mujeeb-logo.css";
import "@/assets/css/utils.scss";

import VCalendar from "v-calendar";
import "v-calendar/lib/v-calendar.min.css";
Vue.use(VCalendar);

// import "./registerServiceWorker";
import loadingSppiner from "./components/loading/loading-spinner.vue";

// debounce plugin
import Debounce from "./plugins/vuejs/debounce.plugin";
Vue.use(Debounce);

// Auth0 plugin
import AuthPlugin from "./plugins/vuejs/auth0.plugin";
Vue.use(AuthPlugin);

// Api plugin
import ApiPlugin from "./plugins/vuejs/api.plugin";
import ApiService from "./services/api.service";
ApiService.init(process.env.VUE_APP_ROOT_API);
ApiService.mount401Interceptor();
Vue.use(ApiPlugin);

// getAtPath plugin
import getAtPath from "./plugins/vuejs/path.plugin";
Vue.use(getAtPath);

// vue-textarea-autosize plugin
import VueTextareaAutosize from "vue-textarea-autosize";
Vue.use(VueTextareaAutosize);

// vuelidate
import Vuelidate from "vuelidate";
Vue.use(Vuelidate);

// buefy
// import Buefy from "custom-buefy-ar-rtl";
// Vue.use(Buefy, {
//   defaultIconPack: "fa",
//   defaultIconComponent: "font-awesome-icon"
import Buefy from "buefy";
// import "buefy/dist/buefy.css";

Vue.use(Buefy, { defaultIconPack: "fa", defaultIconComponent: "font-awesome-icon" });

// vue tour
import VueTour from "vue-tour";
Vue.use(VueTour);
require("./assets/css/vue-tour.css");

// chart
import VueApexCharts from "vue-apexcharts";
Vue.component("apexchart", VueApexCharts);

// vuex-route sync
import { sync } from "vuex-router-sync";
sync(store, router);

// click out side
import vClickOutside from "v-click-outside";
Vue.use(vClickOutside);

// register the loading componenet
Vue.component("loading-spinner", loadingSppiner);

import stateModal from "./components/stateModal";
Vue.component("async-state-modal", stateModal);

import "./assets/tailwind.css";

// register success modal mixin
Vue.mixin({
  data() {
    return {
      // eslint-disable-next-line vue/no-reserved-keys
      async_operation_state: false,
      show_async_operation_state: false,
      state_text: ""
    };
  },
  methods: {
    showState(state) {
      this.show_async_operation_state = true;
      this.async_operation_state = state.state;
      const that = this;
      this.state_text = state.text;
      setTimeout(() => {
        that.show_async_operation_state = false;
      }, 1000);
    }
  }
});

// register componentName mixin
Vue.use(require("./mixins/componentNameMixin"));

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  created() {
    store.dispatch("page/readPageFromBrowserStorage");
  },
  render: h => h(App)
}).$mount("#app");
