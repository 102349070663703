export default {
  namespaced: true,
  state: {
    cartViewProperties: [
      {
        name: "select",
        title: "",
        cssClass: "checkBox"
      },
      {
        name: "checkout_time",
        title: "تاريخ الشراء",
        cssClass: "date",
        orderBy: true,
        orderByPropertyName: "checkout_time"
      },
      {
        name: "products",
        title: "المنتجات",
        cssClass: "products"
      },
      {
        name: "buyer",
        title: "المشتري",
        cssClass: "buyer"
      },
      {
        name: "receipt",
        title: "المبلغ الكلي",
        cssClass: "receipt"
      },
      {
        name: "options",
        title: "",
        cssClass: "options"
      }
    ],
    buyerViewProperties: [
      {
        name: "picture",
        title: "",
        cssClass: "picture"
      },
      {
        name: "name",
        title: "اسم المشتري",
        cssClass: "name",
        orderBy: true,
        orderByPropertyName: "buyer.name"
      },
      {
        name: "last_purchase_date",
        title: "آخر عملية شراء",
        cssClass: "date",
        orderBy: true,
        orderByPropertyName: "last_purchase_date"
      },
      {
        name: "purchases_count",
        title: "المشتريات",
        cssClass: "buyer"
      },
      {
        name: "total_purchases",
        title: "المبلغ الكلي",
        cssClass: "receipt"
      },
      {
        name: "options",
        title: "",
        cssClass: "options"
      }
    ]
  },
  getters: {
    cartViewProperties: (state, getters, rootState, rootGetters) => state.cartViewProperties,
    buyerViewProperties: (state, getters, rootState, rootGetters) => state.buyerViewProperties
  }
};
