<template>
  <!-- <loading-spinner v-if="$store.getters['renewingToken']"></loading-spinner> -->

  <div id="#home" :class="{ 'stop-scroll': stopScroll }">

    <div class="notification-banner">
        <p>
           في حال مواجهة مشكلة في تسجيل الدخول يرجى التأكد من استخدام بروكسي لأن بعض الخدمات التي يستخدمها مجيب محجوبة عن سوريا.
        </p>
      </div>

    <div
      class="top-nav flex flex-row content-center items-center w-full m-auto fixed inset-x-0 top-0"
    >
      <VLazyImage
        class="nav-brand-logo w-100"
        src="https://images-mujeeb.ams3.cdn.digitaloceanspaces.com/Mujeeb_Images/Mujeeb_Images/mujeeb%20name.png"
      ></VLazyImage>
      <div class="spacer"></div>
      <div class="nav-menu">
        <button
          class="text-white bg-mujeeb-blue rounded-full px-4 py-2 flex items-center content-center font-bold"
          :disabled="$store.getters['authenticatingUser']"
          @click="items[0].atClick"
        >
          {{ items[0].text }}
          <spring-spinner
            v-if="$store.getters['authenticatingUser']"
            :animation-duration="3000"
            :size="25"
            color="#ffffff"
          />
        </button>
        <div class="nav-item"><a href="#pricing">الأسعار</a></div>
      </div>
      <div class="nav-menu-mobile">
        <button
          class="hamburger hamburger--elastic"
          :class="{ 'is-active': show }"
          @click="showMenu"
          type="button"
        >
          <span class="hamburger-box">
            <span class="hamburger-inner"></span>
          </span>
        </button>

        <ul v-if="show">
          <!-- <li v-for="(item, index) in items" :key="index" @click="item.atClick()">
        {{ item.text }}
      </li> -->
          <li @click="$auth.login">تسجيل دخول</li>
          <li @click="goToPricing">الأسعار</li>
        </ul>
      </div>
    </div>

    <section class="m-hero">
      <div class="m-hero-body">
        <div class="hero-text-box text-right">
          <h4>أسهل طريقة لتكون قريباً من زبائنك.</h4>
          <p>حان الوقت لتسترجع وقتك.</p>
          <button
            class="bg-mujeeb-blue float-right text-white rounded-full px-6 font-bold font-2xl flex items-center content-center justify-between"
            :disabled="$store.getters['authenticatingUser']"
            @click.prevent="login"
          >
            سجّل دخولك الآن

            <spring-spinner
              style="margin-left: 10px"
              v-if="$store.getters['authenticatingUser']"
              :animation-duration="3000"
              :size="25"
              color="#ffffff"
            />
          </button>
        </div>
        <div class="hero-image">
          <VLazyImage
            src="https://images-mujeeb.ams3.cdn.digitaloceanspaces.com/Mujeeb_Images/Mujeeb_Images/Hero.png"
          ></VLazyImage>
        </div>
      </div>
    </section>

    <div class="customers bg-mujeeb-blue p-4 p-t-6 p-b-6">
      <p
        class="flex justify-center items-center content-center text-white text-2xl font-bold mb-12"
      >
        يستخدمون مجيب
      </p>
      <carousel
        :autoplay="true"
        :autoplayTimeout="3000"
        :loop="true"
        v-if="items.length > 0"
        :perPageCustom="[
          [330, 1],
          [400, 1],
          [680, 2],
          [768, 3],
          [1024, 3],
        ]"
        paginationColor="#adadad"
        paginationActiveColor="#228cff"
        :paginationEnabled="false"
        easing="ease-in"
        :paginationSize="30"
      >
        <slide
          class="flex justify-center items-center content-center cursor-pointer"
          v-for="(item, key) in customers"
          :key="key"
        >
          <img :src="getImgUrl(item)" alt="" srcset="" />
        </slide>
      </carousel>
    </div>

    <div id="pricing" class="flex pt-12 pb-16 justify-center items-center content-center flex-wrap">
      <div class="">
        <!-- <div class="pricing-controll p-6 flex justify-center items-center content-center flex-col">
      <p>الأسعار</p>
      <p class="text-xl mb-6">هل تريد معرفة السعر في حال الرغبة </p>
      <b-checkbox 
        class="rtl"
        :value="true"
        type="is-info">
          الدفع لمدة سنة
      </b-checkbox>
    </div> -->

        <div class="flex justify-center items-center content-center flex-wrap">
          <div
            class="package-card shadow bg-white rounded"
            v-for="(m_package, index) in packages"
            :key="index"
          >
            <p class="package-name">{{ m_package.name }}</p>
            <p class="package-price">{{ m_package.price }}</p>
            <div class="package-description" v-if="m_package.description">
              <p>{{ m_package.description }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="inpress bg-mujeeb-blue p-4 p-t-6 p-b-6">
      <p
        class="flex justify-center items-center content-center text-white text-2xl font-bold mb-12"
      >
        مجيب في الإعلام
      </p>
      <carousel
        :autoplay="true"
        :autoplayTimeout="4000"
        :loop="true"
        v-if="items.length > 0"
        :perPageCustom="[
          [320, 1],
          [480, 1],
          [680, 3],
          [768, 3],
          [1024, 3],
        ]"
        paginationColor="#adadad"
        paginationActiveColor="#228cff"
        :paginationEnabled="false"
        :paginationSize="30"
      >
        <slide
          class="flex justify-center items-center content-center cursor-pointer"
          v-for="(item, key) in inPress"
          :key="key"
        >
          <a :href="item.url" target="_blank"><img :src="item.image" alt="" srcset="" /></a>
        </slide>
      </carousel>
    </div>

    <footer>
      <div>
        <p>
          <a style="margin-top: 20px; line-height: 30px" href="tel:+963957233971">{{ phone1 }}</a>
        </p>
        <p style="magrin-top: 15px">
          <a style="margin-top: 20px; line-height: 30px" href="tel:+963957233973">{{ phone2 }}</a>
        </p>
        <p style="magrin-top: 15px">
          <a style="margin-top: 20px; line-height: 30px" href="tel:+963959305193">{{ phone3 }}</a>
        </p>
      </div>
      <div class="mt-4">
        <div class="">
          <ul class="social-links">
            <li>
              <a href="https://www.facebook.com/mujeeb.bot/" target="_blank">
                <font-awesome-icon :icon="['fab', 'facebook']" />
                facebook
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="mujeeb-info">
        <!-- <p>Need help? Email us at <br /><a href="mailto:info@mujeeb.ai">info@mujeeb.ai</a></p> -->
        <p>Mujeeb © 2021 <br /></p>
      </div>
    </footer>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
import VLazyImage from "v-lazy-image";
import SpringSpinner from "./../components/loading/SpringSpinner";
export default {
  name: "home",
  components: {
    Carousel,
    Slide,
    VLazyImage,
    SpringSpinner,
  },
  data() {
    return {
      phone1: +963957233971,
      phone2: +963957233973,
      show: false,
      items: [
        { text: "تسجيل دخول", atClick: this.$auth.login },
        {
          text: "الأسعار",
          atClick: () => {
            this.show = false;
          },
          innerHTML: '<a href="#pricing" _target="black></a>',
        },
      ],
      inPress: [
        {
          image: "https://s3.eu-central-1.amazonaws.com/mujeeb.ai/static/svg/arabnet.svg",
          url: "https://news.arabnet.me/jusoors-3rd-entrepreneurship-competition-winners-unveiled/",
        },
        {
          image: "https://s3.eu-central-1.amazonaws.com/mujeeb.ai/static/svg/enterpreunur.svg",
          url: "https://www.entrepreneur.com/article/289466",
        },
        {
          image: "https://s3.eu-central-1.amazonaws.com/mujeeb.ai/static/svg/eqtisady.svg",
          url: "https://ae.aliqtisadi.com/908850-%D8%AC%D8%B3%D9%88%D8%B1-%D8%AA%D8%AF%D8%B9%D9%85-%D8%A7%D9%84%D8%AC%D9%8A%D9%84-%D8%A7%D9%84%D9%82%D8%A7%D8%AF%D9%85-%D9%85%D9%86-%D8%B1%D8%AC%D8%A7%D9%84-%D8%A7%D9%84%D8%A3%D8%B9%D9%85%D8%A7%D9%84/",
        },
        {
          image: "https://s3.eu-central-1.amazonaws.com/mujeeb.ai/static/svg/futurism.svg",
          url: "https://mostaqbal.ae/%D9%85%D8%AC%D9%8A%D8%A8-%D8%A8%D9%88%D8%AA%D8%A7%D8%AA-%D8%AF%D8%B1%D8%AF%D8%B4%D8%A9-%D8%B0%D9%83%D9%8A%D8%A9-%D8%AA%D9%81%D9%87%D9%85-%D9%84%D8%BA%D8%A9-%D8%A7%D9%84%D9%85%D8%AA%D9%86%D8%A8%D9%8A/",
        },
        {
          image: "https://s3.eu-central-1.amazonaws.com/mujeeb.ai/static/svg/theguardian.svg",
          url: "https://www.theguardian.com/small-business-network/2017/may/30/syria-young-entrepreneurs-war-jusoor-mujeeb-techstars-unfpa-startup-weekend-remmaz",
        },
        {
          image: "https://s3.eu-central-1.amazonaws.com/mujeeb.ai/static/svg/wamda.svg",
          url: "https://www.wamda.com/2017/04/Syrian-entrepreneurs-raise-voices-Jusoor",
        },
      ],
      packages: [
        {
          name: "الشريحة الاقتصادية",
          price: "20,000",
          description: "تتيح هذه الفئة الإجابة على 500 تعليق على كل منشور",
        },
        {
          name: "شريحة النمو",
          price: "25,000",
          description: "تتيح هذه الفئة الإجابة على 1000 تعليق ضمن منشور",
        },
        {
          name: "الشريحة المتوسطة",
          price: "35,000",
          description: "تتيح هذه الفئة الإجابة على 1500 تعليق ضمن منشور",
        },
        {
          name: "الشريحة التجارية",
          price: "40,000",
          description: "تتيح هذه الفئة الإجابة على 4000 تعليق ضمن منشور",
        },
        {
          name: "الشريحة الغير محدودة",
          price: "50,000",
          description: "تتيح هذه الفئة الإجابة على عدد غير محدود  من التعليقات ضمن منشور",
        },
      ],
      customers: [
        "400.svg",
        "CHOCOSWAMP.svg",
        "hadara.svg",
        "dmc.svg",
        "MABCO.svg",
        "SAMATEL.svg",
        "sharp.svg",
        "adidas.svg",
        "shibly.svg",
        "diamond_smile.svg",
        "vita.svg",
        "daboul.svg",
      ],
      stopScroll: false,
    };
  },
  methods: {
    login() {
      this.$store.dispatch("setLoggingState", true);
      this.$auth.login();
    },
    showMenu() {
      this.show = !this.show;
      this.stopScroll = !this.stopScroll;
    },
    getImgUrl(pic) {
      return require("@/assets/svg/" + pic);
    },
    goToPricing() {
      this.show = false;
      const element = document.getElementById("pricing");
      element.scrollIntoView();
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("./../assets/css/humberger.scss");

.notification-banner {
  background-color: #33b249;
  padding: 25px 50px;
  text-align: center;
  font-size: 20px;
  color: rgb(255, 255, 255);
  font-weight: 600;
  bottom: 0;
  left: 0;
  width: 100%;
  position: fixed;
  z-index: 999;
  direction: rtl;
}
.top-nav {
  height: 70px;
  background-color: white;
  // height: 60px;
  padding-left: 5%;
  padding-right: 5%;
  z-index: 10;
  .nav-menu {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    div {
      font-weight: bold;
      margin-left: 20px;
    }
    a {
      &:active,
      &:link,
      &:hover,
      &:hover {
        color: $mujeeb-blue;
      }
    }
    .nav-item {
      margin-right: 20px;
      margin-left: 20px;
      align-self: center;
      display: inline-block;
      border-bottom: 1px solid rgba(0, 123, 254, 0);
      transition: all 0.5s;
      padding-bottom: 5px;
      cursor: pointer;
      color: $mujeeb-blue;
      font-size: 110%;
    }
    .nav-item:hover {
      border-bottom: 1px solid $mujeeb-blue;
    }
  }
  a {
    &:active,
    &:link,
    &:hover,
    &:hover {
      color: $mujeeb-blue;
    }
  }
  .nav-brand-logo {
    height: 40%;
  }
}

.m-hero {
  .m-hero-head {
    max-width: 1200px;
  }
  .m-hero-body {
    .hero-text-box {
      padding-right: 30px;
      h4,
      p {
        direction: rtl;
        font-family: Cairo !important;
      }
      button {
        margin: 40px 0px 0px 0px;
      }
    }
  }
}

.inpress,
.customers {
  .VueCarousel-slide {
    img {
      height: 30px;
    }
  }
}
.inpress {
  padding: 30px 0px;
}
.customers {
  padding: 30px 0px;
  p {
    font-family: Cairo;
  }
  .VueCarousel-slide {
    img {
      height: 60px;
    }
  }
}

.package-card {
  width: 300px;
  padding: 5px;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 5px;
  p {
    text-align: center;
    font-family: nunito;
    // color: white !important;
  }
  .package-name,
  .package-name,
  .package-name {
    padding: 10px;
  }

  .package-name {
    font-size: 25px;
    margin-bottom: 20px;
    font-weight: bold;
    font-family: cairo !important;
    height: 57px;
  }
  .package-price {
    font-size: 50px;
    margin-bottom: 20px;
    font-weight: bold;
    font-family: cairo !important;
    height: 75px;
  }
  .package-description {
    p {
      font-family: Cairo;
      font-size: 20px;
      height: 113px;
      padding: 10px;
    }
  }
}
.pricing-controll {
  font-family: Cairo !important;
  p:first-child {
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 950px) {
  .m-hero-body {
    padding-top: 60px !important;
    .hero-text-box {
      flex-basis: 100% !important;
    }
  }
}

@media only screen and (max-width: 450px) {
  .package-card {
    width: 100%;
  }
}

/* --------------------------------------------*/
/* NAV BAR
/* --------------------------------------------*/

.nav-menu-mobile {
  display: none;
  height: 100%;
}

.nav-menu-mobile ul {
  background-color: white;
  position: absolute;
  top: 60px;
  right: 0;
  left: 0;
  width: 100vw;
  height: calc(100vh - 60px);
  text-align: center;
  padding: 40px 0px 0px 0px;
  li {
    align-items: center;
    color: #00b1ff;
    cursor: pointer;
    display: flex;
    font-size: 2em;
    height: 3em;
    justify-content: center;
    list-style-type: none;
    transition: all 0.4s ease;
    &:hover {
      background-color: #00b1ff;
      color: white;
    }
  }
}

.stop-scroll {
  overflow: hidden;
}

.m-hero {
  margin-top: 60px;
  display: flex;
  .m-hero-body {
    padding: 30px;
    margin: auto;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-wrap: wrap;
    max-width: 1200px;
    .hero-text-box {
      line-height: 300%;
      flex: 1 1 50%;
      p {
        line-height: 50px;
        word-spacing: 4px;
        font-weight: 700;
        color: #007afe;
        font-size: 120%;
        word-spacing: normal;
        font-family: Nunito;
      }
      h4 {
        line-height: 50px;
        word-spacing: 4px;
        font-weight: 700;
        color: #00769b;
        font-size: 200%;
        word-spacing: normal;
        font-family: Nunito;
      }
    }

    .hero-image {
      flex: 2 1 300px;
      min-width: 300px;
    }
  }
}

/* --------------------------------------------*/
/* FOOTER SECTION
/* --------------------------------------------*/
footer {
  padding: 40px;
  font-size: 20px;
  text-align: center;
  .social-links {
    margin-bottom: 20px;
  }
  .mujeeb-info {
    text-align: center;
  }
}

.menu-is-active {
  background-clip: rgba(0, 0, 0, 0.479);
}

@media only screen and (max-width: 600px) {
  .nav-menu-mobile {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex: 0 0 auto;
  }

  .nav-menu {
    display: none !important;
  }
}
</style>
