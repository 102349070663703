export default {
  // The install method will be called with the Vue constructor as
  // the first argument, along with possible options
  install(Vue, options) {
    Vue.prototype.$getAtPath = (arr, obj) => {
      let val = obj;
      if (!val) {
        return null;
      }
      for (let i = 0; i < arr.length; i++) {
        const prop = arr[i];
        if (val[prop] == null) {
          return;
        }
        val = val[prop];
      }
      return val;
    };
  }
};
