export const componentName = {
  computed: {
    componentName_mixin() {
      if (this.$root === this) {
        return "root instance";
      }
      return this._isVue ? this.$options.name || this.$options._componentTag : this.name;
    }
  }
};
