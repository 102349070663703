import debounce from "debounce";

export default {
  install(Vue) {
    Vue.prototype.$debounce = debounce;
    Vue.prototype.$randomId = randomId;
  }
};

function randomId(len) {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < len; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
