import localforage from "localforage";
import storageNames from "../../configs/storageKeysNames.json";
export default store => {
  store.subscribe((mutation, state) => {
    if (mutation.type === "setLoggingState") {
      if (state.loggingState != null) {
        localforage.setItem(storageNames.loggingState, state.loggingState);
      }
    }
  });
};
