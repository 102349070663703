export default {
  namespaced: true,
  state: {
    windowWidth: null
  },
  mutations: {
    setWindowWidth(state, width) {
      state.windowWidth = width;
    }
  },
  actions: {
    setWindowWidth({ commit }, width) {
      commit("setWindowWidth", width);
    }
  },
  getters: {
    windowWidth: state => state.windowWidth
  }
};
