import localforage from "localforage";
import storageNames from "../../configs/storageKeysNames.json";
export default store => {
  store.subscribe((mutation, state) => {
    if (
      mutation.type === "route/ROUTE_CHANGED" &&
      state.route.name !== "loadingScreen" &&
      state.route.name !== "callback"
    ) {
      localforage.setItem(storageNames.route, state.route).catch(err => {
        console.log(err);
      });
    }
  });
};
