import authService from "../../services/auth0.service";
import events from "../../configs/eventsconfig.json";

export default {
  install(Vue) {
    Vue.prototype.$auth = authService;
    Vue.mixin({
      created() {
        if (this.handleLoginEvent) {
          authService.addListener(events.loginEvent, this.handleLoginEvent);
        }
        if (this.renewTokenEventHandler) {
          authService.addListener(events.renewToken, this.renewTokenEventHandler);
        }
        // if (this.handleLogUserIn) {
        //   authService.addListener(events.logUserIn, this.handleLogUserIn);
        // }
      },

      destroyed() {
        if (this.handleLoginEvent) {
          authService.removeListener(events.loginEvent, this.handleLoginEvent);
        }
        if (this.renewTokenEventHandler) {
          authService.removeListener(events.renewToken, this.renewTokenEventHandler);
        }
      }
    });
  }
};
