export default {
  namespaced: true,
  state: {
    nextStartAfter: null,
    backStartAfter: null,
    pseudoBack: null,
    removedItems: [],
    addedItems: []
  },
  mutations: {
    setNextStartAfter(state, payload) {
      state.nextStartAfter = payload;
    },
    setBackStartAfter(state, payload) {
      state.backStartAfter = payload;
    },
    resetPointers(state) {
      state.nextStartAfter = null;
      state.backStartAfter = null;
    },
    setPseudoBack(state, payload) {
      state.pseudoBack = payload;
    }
  },
  actions: {
    setNextStartAfter({ dispatch, commit, getters, rootGetters }, payload) {
      return new Promise((resolve, reject) => {
        try {
          commit("setNextStartAfter", payload);
          resolve();
        } catch (error) {
          reject();
        }
      });
    },
    setBackStartAfter({ dispatch, commit, getters, rootGetters }, payload) {
      return new Promise((resolve, reject) => {
        try {
          commit("setBackStartAfter", payload);
          resolve();
        } catch (error) {
          reject();
        }
      });
    },
    resetPointers({ dispatch, commit, getters, rootGetters }, payload) {
      return new Promise((resolve, reject) => {
        try {
          commit("resetPointers");
          resolve();
        } catch (error) {
          reject(error);
        }
      });
    },
    setPseudoBack({ dispatch, commit, getters, rootGetters }, payload) {
      return new Promise((resolve, reject) => {
        try {
          commit("setPseudoBack", payload);
          resolve();
        } catch (error) {
          reject();
        }
      });
    }
  },
  getters: {
    nextStartAfter: (state, getters, rootState, rootGetters) => {
      return state.nextStartAfter;
    },
    backStartAfter: (state, getters, rootState, rootGetters) => {
      return state.backStartAfter;
    },
    pseudoBack: (state, getters, rootState, rootGetters) => {
      return state.pseudoBack;
    }
  }
};
