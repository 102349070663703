<template>
  <div id="app">
    <transition name="fade">
      <router-view />
    </transition>
  </div>
</template>

<script>
import ApiService from "./services/api.service";
import { StorageService } from "./services/storage.service";
import { EventBus } from "./utils/EventBus";
export default {
  data() {
    return {};
  },
  created() {
    this.$store.dispatch("setAuthenticatingUser", true);
    this.$auth.renewTokens();
  },
  methods: {
    renewTokenEventHandler(authResult) {
      if (authResult && authResult.loggedIn) {
        console.log("user is logged in");
        // todo change the route to a loading screen
        this.$router.push({ name: "loadingScreen" });
        return this.$store
          .dispatch("user/setUser", this.$auth.profile)
          .then(() => {
            return this.$api.customRequest({
              method: "get",
              url: `/user/login`,
              params: {
                userId: `${this.$store.getters["user/id"]}`
              },
              responseType: "json"
            });
          })
          .then(response => {
            if (response.status === 201) {
              this.$store.dispatch("setPages", response.data.pages);
              this.$router.push({ name: "pageSelection" });
            } else if (response.status === 200) {
              this.$store
                .dispatch("page/setPage", response.data.page)
                .then(() => StorageService.savePage(response.data.page))
                .then(() => {
                  return this.$store
                    .dispatch("InitializeRouter", authResult.state)
                    .then(() => this.$store.dispatch("setAuthenticatingUser", false));
                });
            } else if (response.status === 202) {
              this.$auth.login();
            }
          })
          .catch(error => {
            console.log(error);
            // if (error.response.status === 404) console.log(error);
            this.$router.push("/");
            this.$store.dispatch("setAuthenticatingUser", false);
          });
      } else if (!authResult.loggedIn) {
        console.log("user is NOT Logged in");
        // TODO delelet all storage data
        return StorageService.clearData().finally(() => {
          this.$store.dispatch("setAuthenticatingUser", false);
          const resolved = this.$router.resolve(window.location);
          if (resolved.route.name === "notFound") {
            this.$router.push({ name: "notFound" });
          } else if (window.location.pathname != "/" && window.location.pathname != "/callback") {
            this.$auth.login({ _redirectTo: window.location.pathname });
          }
        });
      }
    }
  }
};
</script>

<style lang="scss">
// Import Bulma's core
$block-spacing: 2px;
@import "~bulma/sass/utilities/_all";
// node_modules\bulma\sass\utilities
// Set your colors
$primary: dodgerblue;
$primary-invert: findColorInvert($primary);

// Setup $colors to use as bulma classes (e.g. 'is-twitter')
$colors: (
  "white": (
    $white,
    $black
  ),
  "black": (
    $black,
    $white
  ),
  "light": (
    $light,
    $light-invert
  ),
  "dark": (
    $dark,
    $dark-invert
  ),
  "primary": (
    $primary,
    $primary-invert
  ),
  "info": (
    $info,
    $info-invert
  ),
  "success": (
    $success,
    $success-invert
  ),
  "warning": (
    $warning,
    $warning-invert
  ),
  "danger": (
    $danger,
    $danger-invert
  )
);

.dialog.modal.is-active {
  .modal-card.animation-content {
    .modal-card-body.is-titleless {
      .media-content {
        direction: rtl;
        text-align: right;
      }
    }
  }
}

// Links
$link: $primary;
$link-invert: $primary-invert;
$link-focus-border: $primary;

// Import Bulma and Buefy styles
@import "~bulma";
@import "~buefy/src/scss/buefy";
</style>
